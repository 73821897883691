.drawerToggle {
  width: 3rem;
  min-width: 2.5rem;
  height: 2rem;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.drawerToggle div {
  width: 60%;
  height: 3px;
  background-color: #000000;
}

@media (min-width: 651px) {
  .drawerToggle {
    display: none;
  }
}/*# sourceMappingURL=DrawerToggle.module.css.map */