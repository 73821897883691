@import "../../../sassVariables/variables";

.sidedrawer {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 12rem;
  max-width: 70%;
  height: 100%;
  right: 0rem;
  z-index: 200;
  background-color: $VINA_LIGHT_GREY;
  padding: 2rem 1rem;
  box-sizing: border-box;
  box-shadow: 0 0 1px $VINA_BLACK;
}

@media (min-width: 651px) {
  .sidedrawer {
    display: none;
  }
}
