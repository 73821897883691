@import url("https://fonts.googleapis.com/css2?family=Benne&family=Cabin&family=Catamaran&family=Charis+SIL&family=EB+Garamond&family=Heebo&family=Joan&family=Lora&family=Mukta&family=Roboto&family=STIX+Two+Text&family=Source+Sans+Pro&family=Source+Serif+4&family=Source+Serif+Pro&family=Tiro+Bangla&family=Work+Sans&display=swap");
* {
  box-sizing: border-box;
}

html {
  font-family: "Source Sans Pro", sans-serif;
}

button {
  font-family: "Source Sans Pro", sans-serif;
}

body {
  margin: 0;
  background-color: #ffffff;
}

main {
  text-align: center;
}

h1,
h2,
h3,
p {
  color: #000000;
  text-align: center;
}/*# sourceMappingURL=index.css.map */