@import "../sassVariables/variables";

.container {
  @include flexCentered(column);
}

section {
  @include flexCentered(column);
}

.home1 {
  margin-top: -9.5rem;
  width: 100%;
  height: 50rem;
  @include bgImg("/public/images/stockholm_01.jpg");
}

.home2 {
  height: 38rem;
}

.home3 {
  height: 36rem;
}

.home4 {
  width: 100%;
  height: 36rem;
  box-shadow: 0 0 1px $VINA_BLACK;
  @include bgImg("/public/images/home_map.png");
}

.aboutUs1 {
  margin-top: -9.5rem;
  width: 100%;
  height: 50rem;
  @include bgImg("/public/images/om_oss_04.jpg");
}

.aboutUs2 {
  height: 38rem;
}

.aboutUs3 {
  height: 40rem;
  background-color: $VINA_LIGHT_GREY;
}

.aboutUs4 {
  height: 32rem;
}

.vacancy1 {
  margin-top: -9.5rem;
  width: 100%;
  height: 50rem;
  @include bgImg("/public/images/vacancy_03.jpg");
}

.vacancy2 {
  height: 36rem;
}

.vacancy3 {
  height: 45rem;
  background-color: $VINA_LIGHT_GREY;
}

.vacancy31 {
  height: 30rem;
}

.vacancy4 {
  height: 36rem;
  width: 100%;
  @include bgImg("/public/images/ansökBG.jpg");
}

.contact1 {
  margin-top: -9.5rem;
  width: 100%;
  height: 50rem;
  @include bgImg("/public/images/contact_05.jpg");
}

.contact2 {
  height: 50rem;
}

.contact3_1 {
  height: 40rem;
  background-color: $VINA_LIGHT_GREY;
}
.contact3_2 {
  height: 40rem;
}

.contact4 {
  height: 36rem;
  width: 100%;

  @include bgImg("/public/images/kontaktBG.jpg");
}

@media (max-width: 650px) {
  .links {
    width: 25rem;
    @include flexCentered();
  }

  .container {
    transform: scale(0.8);
  }

  .home4 {
    width: 100%;
    height: 15rem;
  }

  .vacancy31 {
    height: 28rem;
  }

  .contact2 {
    height: 65rem;
  }
  .contact3_1 {
    height: 30rem;
  }
  .contact3_2 {
    height: 35rem;
  }

  .contact4 {
    height: 40rem;
  }
}

@media (max-width: 863px) {
  .home3 {
    height: 48rem;
    transform: scale(0.8);
  }
}

@media (min-height: 1450px) {
  .home1 {
    margin-top: -12rem;
  }
}

@media (min-height: 2500px) {
  .home1 {
    margin-top: -14rem;
  }
}
