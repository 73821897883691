@media (max-width: 800px) {
  .navigator nav {
    height: 100%;
  }
  .navigator p {
    text-align: right;
    margin-right: 1rem;
  }
  .navigator a {
    font-size: 0.9rem;
    color: #000000;
    text-decoration: none;
  }
  .navigator a:hover,
.navigator a:active,
.navigator a.active {
    border-style: solid;
    border-color: #666666;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 1px;
  }
}/*# sourceMappingURL=Navigator.module.css.map */