$VINA_GOLD: #f4cd00;
$VINA_LIGHT_GOLD: #fffaca;
$VINA_WHITE: #ffffff;
$VINA_BLACK: #000000;
$VINA_TRASPARENT: rgba(0, 0, 0, 0.75);
$VINA_WHITE_TRASPARENT: rgba(255, 255, 255, 0.75);
$VINA_LIGHT_GREY: #e9e7e3;

$VINA_FONT: "Source Sans Pro", sans-serif;

@mixin flexCentered($flex-direction: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $flex-direction;
}

@mixin bgImg($image) {
  background-image: url($image);
  background-size: cover;
  background-position: bottom;
}

@mixin baseButton() {
  border-color: $VINA_LIGHT_GREY;
  border-width: 1px;
  border-radius: 5px;
  font-size: 1rem;
  background-color: $VINA_WHITE;
  color: $VINA_BLACK;
}
