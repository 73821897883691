@import "../../../sassVariables/variables";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  background: $VINA_TRASPARENT;
  overflow: hidden;
}
