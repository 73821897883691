@import "../../../sassVariables/variables";

.fourCardsGroupContainer {
  @include flexCentered(column);
}

.itemsContainer {
  @include flexCentered(column);
}

.row {
  @include flexCentered(row);
}

@media (max-width: 650px) {
  .row {
    flex-direction: column;
    margin-bottom: -1.8rem;
  }

  .itemsContainer {
    margin-top: 0rem;
  }
}
