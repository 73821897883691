.standardCard {
  position: relative;
  width: 30rem;
  height: 16rem;
  transition-property: transform;
  transition-duration: 0.4s;
}

.title {
  color: #000000;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.65rem;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  font-size: 1.3rem;
  color: #000000;
  margin-top: 0.5rem;
}

.standardCard:hover {
  transform: scale(1.1);
}/*# sourceMappingURL=StandardCard.module.css.map */