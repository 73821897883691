.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -3rem;
}

.vacancyCardDetails {
  z-index: 4000;
  background-color: #e9e7e3;
  border-radius: 2%;
  height: 35rem;
  overflow-y: auto;
}

.shortText {
  width: 24rem;
  margin: 1.5rem auto;
}

.title {
  color: #000000;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.65rem;
}

.vacancyCardDetails ul {
  margin: 2rem 0;
}

.vacancyCardDetails li {
  text-align: left;
  margin: 0 0.5rem;
}

.text {
  text-align: left;
  margin: 0 0.5rem;
}

@media (max-width: 650px) {
  .container {
    width: 20rem;
    height: 35rem;
  }
  .vacancyCardDetails {
    width: 20rem;
    height: 32rem;
    font-size: 0.8rem;
  }
  .title {
    font-size: 1.1rem;
  }
  .shortText {
    width: 18rem;
  }
}/*# sourceMappingURL=VacancyCardDetails.module.css.map */