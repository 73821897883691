@import url("https://fonts.googleapis.com/css2?family=Joan&family=Lato&family=Lexend+Deca&family=Roboto&family=Tajawal&display=swap");
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 23rem;
  height: 27rem;
  margin: 0 auto;
}

.form {
  display: flex;
  justify-content: #ffffff;
}

.container p {
  width: 40rem;
}

.title {
  font-size: 1.4em;
  font-weight: bold;
  color: #000000;
}

.double {
  display: flex;
  justify-content: space-between;
  width: 20rem;
  margin: 0 auto;
}

.form input {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.85rem;
  margin: 3px 0px 0px 0px;
  width: 20rem;
  height: 3rem;
  background-color: #e9e7e3;
  border-width: 1px;
  border-style: solid;
  border-color: #e9e7e3;
}

.errorContainer {
  height: 0.8rem;
  width: 20rem;
  margin: 0 auto;
}

.form .error {
  border-color: red;
  background-color: #e9e7e3;
}

.form input::-moz-placeholder {
  font-size: 0.9rem;
  padding: 0rem 0.3rem;
  color: #000000;
}

.form input:-ms-input-placeholder {
  font-size: 0.9rem;
  padding: 0rem 0.3rem;
  color: #000000;
}

.form input::placeholder {
  font-size: 0.9rem;
  padding: 0rem 0.3rem;
  color: #000000;
}

.form input:focus {
  background-color: #ffffff;
  outline: none;
}

.form textarea {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.85rem;
  width: 20rem;
  height: 9.2rem;
  margin: 2px 28px 12px 28px;
  background-color: #e9e7e3;
  border-width: 1px;
  border-color: #e9e7e3;
  padding-top: 0.9rem;
}

.form textarea::-moz-placeholder {
  color: #000000;
  padding: 0rem 0.3rem;
  font-size: 0.9rem;
}

.form textarea:-ms-input-placeholder {
  color: #000000;
  padding: 0rem 0.3rem;
  font-size: 0.9rem;
}

.form textarea::placeholder {
  color: #000000;
  padding: 0rem 0.3rem;
  font-size: 0.9rem;
}

.form textarea:focus {
  background-color: #ffffff;
  outline: none;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 7rem;
  margin: 1rem auto;
}

button {
  width: 7rem;
  height: 2.7rem;
  border-color: #e9e7e3;
  border-width: 1px;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #ffffff;
  color: #000000;
}

button:hover {
  background-color: #e9e7e3;
  color: #000000;
  border-color: #ffffff;
  cursor: pointer;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #e9e7e3;
  color: #93938c;
  border-color: #ffffff;
  cursor: not-allowed;
}

.errorMsg {
  height: 0.8rem;
  font-size: 0.5rem;
  color: red;
  text-align: left;
  padding: 1px 3px;
}

.confirmMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23rem;
  height: 3rem;
  background-color: #e9e7e3;
  margin: 5rem auto;
  font-size: 1.5rem;
  color: #000000;
}

@media (max-width: 650px) {
  .form {
    flex-direction: column;
  }
  .form textarea {
    margin-top: 15px;
  }
  .container {
    height: 42rem;
  }
  .container p {
    width: 23rem;
  }
}/*# sourceMappingURL=ContactForm.module.css.map */