@import "../../../sassVariables/variables";

.lightCard {
  @include flexCentered(column);
  margin: -2rem auto 3rem auto;

  p {
    margin: 0 auto;
    width: 40rem;
    font-size: 1.3rem;
  }

  .lightCardTitle {
    margin: 3rem 0 1rem;
    font-size: 1.4rem;
  }
}

@media (max-width: 650px) {
  .lightCard {
    p {
      width: 25rem;
    }
  }
}
