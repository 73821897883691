@import "../../../sassVariables/variables";

.footer {
  @include flexCentered(column);
  position: relative;
  bottom: 0;
  width: 100%;
  height: 7.5rem;
  background: VINA_LIGHT_GREY;
  box-shadow: 0px 0px 1px $VINA_BLACK;
}

.footer a {
  text-decoration: none;
  color: $VINA_BLACK;
}

.footer div {
  padding: 1rem 0rem;
}

.footer p {
  margin: 0 0 0 0;
  font-size: 0.8rem;
  color: $VINA_BLACK;
  text-align: center;
}
