.vacancyGroupContainer {
  width: 36rem;
  height: 45rem;
  max-width: 1000px;
  z-index: 4000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  margin: 0rem 0 5rem 0;
  font-size: 2rem;
}

.vacancyGroupItems {
  margin: 4rem auto 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
}

h1 {
  text-align: center;
  font-size: 1.3rem;
}

@media (max-width: 650px) {
  .vacancyGroupContainer {
    width: 20rem;
  }
  .vacancyGroupItems {
    margin: 0rem auto 0rem auto;
  }
}/*# sourceMappingURL=VacanciesGroup.module.css.map */