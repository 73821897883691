@import "../../../sassVariables/variables";

.standardCard {
  position: relative;
  width: 30rem;
  height: 16rem;
  transition-property: transform;
  transition-duration: 0.4s;
}

.title {
  color: $VINA_BLACK;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.65rem;
}

.text {
  @include flexCentered();
  text-align: center;
  font-size: 1.3rem;
  color: $VINA_BLACK;
  margin-top: 0.5rem;
}

.standardCard:hover {
  transform: scale(1.1);
}
