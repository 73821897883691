@import "../../../sassVariables/variables";

.closeButton {
  @include flexCentered();
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  z-index: 4000;
  color: $VINA_LIGHT_GREY;
  background-color: $VINA_BLACK;
  font-weight: bold;
  margin: 0.5rem 0rem;
}
