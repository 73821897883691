.fourCardsGroupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.itemsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 650px) {
  .row {
    flex-direction: column;
    margin-bottom: -1.8rem;
  }
  .itemsContainer {
    margin-top: 0rem;
  }
}/*# sourceMappingURL=FourCardsGroupV3.module.css.map */