.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 7.5rem;
  background: VINA_LIGHT_GREY;
  box-shadow: 0px 0px 1px #000000;
}

.footer a {
  text-decoration: none;
  color: #000000;
}

.footer div {
  padding: 1rem 0rem;
}

.footer p {
  margin: 0 0 0 0;
  font-size: 0.8rem;
  color: #000000;
  text-align: center;
}/*# sourceMappingURL=Footer.module.css.map */