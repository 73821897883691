.textContainer {
  margin-top: 5rem;
  position: absolute;
}

.slogan {
  width: 100%;
  color: #ffffff;
  text-align: left;
  font-size: 2.5rem;
  position: relative;
  margin: 1rem 0rem;
  text-shadow: 0px 1px #000000;
  text-align: center;
}

.undertext {
  width: 100%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px #000000;
  font-size: 1.4rem;
  position: relative;
  margin-top: 0;
}

@media (min-height: 1300px) {
  .textContainer {
    margin-top: 2rem;
  }
}
@media (min-height: 1800px) {
  .textContainer {
    margin-top: 0;
  }
} /*# sourceMappingURL=SloganCard.module.css.map */
