@import url("https://fonts.googleapis.com/css2?family=Joan&family=Lato&family=Lexend+Deca&family=Roboto&family=Tajawal&display=swap");

@import "../../sassVariables/variables";

.container {
  @include flexCentered(column);
  width: 23rem;
  height: 27rem;
  margin: 0 auto;
}

.form {
  display: flex;
  justify-content: $VINA_WHITE;
}

.container p {
  width: 40rem;
}

.title {
  font-size: 1.4em;
  font-weight: bold;
  color: $VINA_BLACK;
}
.double {
  display: flex;
  justify-content: space-between;
  width: 20rem;
  margin: 0 auto;
}

.form input {
  font-family: $VINA_FONT;
  font-size: 0.85rem;
  margin: 3px 0px 0px 0px;
  width: 20rem;
  height: 3rem;
  background-color: $VINA_LIGHT_GREY;
  border-width: 1px;
  border-style: solid;
  border-color: $VINA_LIGHT_GREY;
}

.errorContainer {
  height: 0.8rem;
  width: 20rem;
  margin: 0 auto;
}

.form .error {
  border-color: red;
  background-color: $VINA_LIGHT_GREY;
}

.form input::placeholder {
  font-size: 0.9rem;
  padding: 0rem 0.3rem;
  color: $VINA_BLACK;
}

.form input:focus {
  background-color: $VINA_WHITE;
  outline: none;
}

.form textarea {
  font-family: $VINA_FONT;
  font-size: 0.85rem;
  width: 20rem;
  height: 9.2rem;
  margin: 2px 28px 12px 28px;
  background-color: $VINA_LIGHT_GREY;
  border-width: 1px;
  border-color: $VINA_LIGHT_GREY;
  padding-top: 0.9rem;
}

.form textarea::placeholder {
  color: $VINA_BLACK;
  padding: 0rem 0.3rem;
  font-size: 0.9rem;
}

.form textarea:focus {
  background-color: $VINA_WHITE;
  outline: none;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 7rem;
  margin: 1rem auto;
}

button {
  width: 7rem;
  height: 2.7rem;
  @include baseButton();
}

button:hover {
  background-color: $VINA_LIGHT_GREY;
  color: $VINA_BLACK;
  border-color: $VINA_WHITE;
  cursor: pointer;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: $VINA_LIGHT_GREY;
  color: #93938c;
  border-color: $VINA_WHITE;
  cursor: not-allowed;
}

.errorMsg {
  height: 0.8rem;
  font-size: 0.5rem;
  color: red;
  text-align: left;
  padding: 1px 3px;
}

.confirmMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23rem;
  height: 3rem;
  background-color: $VINA_LIGHT_GREY;
  margin: 5rem auto;
  font-size: 1.5rem;
  color: $VINA_BLACK;
}

@media (max-width: 650px) {
  .form {
    flex-direction: column;
  }

  .form textarea {
    margin-top: 15px;
  }

  .container {
    height: 42rem;
  }

  .container p {
    width: 23rem;
  }
}
