.sidedrawer {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 12rem;
  max-width: 70%;
  height: 100%;
  right: 0rem;
  z-index: 200;
  background-color: #e9e7e3;
  padding: 2rem 1rem;
  box-sizing: border-box;
  box-shadow: 0 0 1px #000000;
}

@media (min-width: 651px) {
  .sidedrawer {
    display: none;
  }
}/*# sourceMappingURL=Sidedrawer.module.css.map */