.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  z-index: 4000;
  color: #e9e7e3;
  background-color: #000000;
  font-weight: bold;
  margin: 0.5rem 0rem;
}/*# sourceMappingURL=CloseButton.module.css.map */