.threeCardsGroup {
  margin: 0 auto 0 auto;
  width: 100%;
  height: 25rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-bottom: 0;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: #000000;
}

@media (max-width: 863px) {
  .threeCardsGroup {
    width: 20rem;
    height: 48rem;
    justify-content: center;
  }
  .threeCardsGroup {
    margin-top: 2rem;
  }
}/*# sourceMappingURL=ThreeCardsGroup.module.css.map */