@import "../../../sassVariables/variables";

.vacancyCard {
  @include flexCentered(column);
  height: 9 rem;
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
}

.titleBlock {
  @include flexCentered();
  height: 3rem;
  width: 70%;
}

.textBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  padding: 0px 9px 0px 9px;
  margin-top: 0.5rem;
}

.textBlock p {
  margin: 0 0 0 0;
}

.title {
  color: $VINA_BLACK;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  padding: 0px 9px 0px 9px;
}

.text {
  visibility: visible;
  transform: translate(0rem, -2.55rem);
  transition: visibility 0s, opacity 0.5s linear;
}

.vacancyCard button {
  @include baseButton();
  width: 5.4rem;
  height: 2rem;
  margin: 0.5rem 0rem 2rem;
  font-style: italic;
  padding: 0;
}

.vacancyCard button:hover {
  background-color: $VINA_LIGHT_GREY;
  color: $VINA_BLACK;
  border-color: $VINA_WHITE;
  cursor: pointer;
}

@media (max-width: 650px) {
  .vacancyCard {
    width: 20rem;
  }

  .title {
    font-size: 1.1rem;
  }

  .textBlock {
    font-size: 0.8rem;
    width: 20rem;
  }

  .textBlock p {
    font-size: 0.8rem;
  }

  .vacancyCard button {
    font-size: 0.65rem;
    width: 3.5rem;
    height: 1.4rem;
  }
}
