.finalFooter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 100%;
  height: 13rem;
  background: #e9e7e3;
  box-shadow: 0px 0px 1px #000000;
  bottom: 0;
}

.text {
  font-size: 0.8rem;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
  width: 60%;
}

.container p {
  color: #000000;
  margin: 0 auto;
}

.container a:link {
  text-decoration: none;
  color: #000000;
}

.container div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 6px auto 6px auto;
  color: #000000;
  width: 7rem;
}

@media (max-width: 650px) {
  .container {
    width: 100%;
  }
}/*# sourceMappingURL=FinalFooter.module.css.map */