.lightCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: -2rem auto 3rem auto;
}
.lightCard p {
  margin: 0 auto;
  width: 40rem;
  font-size: 1.3rem;
}
.lightCard .lightCardTitle {
  margin: 3rem 0 1rem;
  font-size: 1.4rem;
}

@media (max-width: 650px) {
  .lightCard p {
    width: 25rem;
  }
}/*# sourceMappingURL=LightCard.module.css.map */