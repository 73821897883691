@charset "UTF-8";
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home1 {
  margin-top: -9.5rem;
  width: 100%;
  height: 50rem;
  background-image: url("/public/images/stockholm_01.jpg");
  background-size: cover;
  background-position: bottom;
}

.home2 {
  height: 38rem;
}

.home3 {
  height: 36rem;
}

.home4 {
  width: 100%;
  height: 36rem;
  box-shadow: 0 0 1px #000000;
  background-image: url("/public/images/home_map.png");
  background-size: cover;
  background-position: bottom;
}

.aboutUs1 {
  margin-top: -9.5rem;
  width: 100%;
  height: 50rem;
  background-image: url("/public/images/om_oss_04.jpg");
  background-size: cover;
  background-position: bottom;
}

.aboutUs2 {
  height: 38rem;
}

.aboutUs3 {
  height: 40rem;
  background-color: #e9e7e3;
}

.aboutUs4 {
  height: 32rem;
}

.vacancy1 {
  margin-top: -9.5rem;
  width: 100%;
  height: 50rem;
  background-image: url("/public/images/vacancy_03.jpg");
  background-size: cover;
  background-position: bottom;
}

.vacancy2 {
  height: 36rem;
}

.vacancy3 {
  height: 45rem;
  background-color: #e9e7e3;
}

.vacancy31 {
  height: 30rem;
}

.vacancy4 {
  height: 36rem;
  width: 100%;
  background-image: url("/public/images/ansökBG.jpg");
  background-size: cover;
  background-position: bottom;
}

.contact1 {
  margin-top: -9.5rem;
  width: 100%;
  height: 50rem;
  background-image: url("/public/images/contact_05.jpg");
  background-size: cover;
  background-position: bottom;
}

.contact2 {
  height: 50rem;
}

.contact3_1 {
  height: 40rem;
  background-color: #e9e7e3;
}

.contact3_2 {
  height: 40rem;
}

.contact4 {
  height: 36rem;
  width: 100%;
  background-image: url("/public/images/kontaktBG.jpg");
  background-size: cover;
  background-position: bottom;
}

@media (max-width: 650px) {
  .links {
    width: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .container {
    transform: scale(0.8);
  }
  .home4 {
    width: 100%;
    height: 15rem;
  }
  .vacancy31 {
    height: 28rem;
  }
  .contact2 {
    height: 65rem;
  }
  .contact3_1 {
    height: 30rem;
  }
  .contact3_2 {
    height: 35rem;
  }
  .contact4 {
    height: 40rem;
  }
}
@media (max-width: 863px) {
  .home3 {
    height: 48rem;
    transform: scale(0.8);
  }
}
@media (min-height: 1450px) {
  .home1 {
    margin-top: -12rem;
  }
}
@media (min-height: 2500px) {
  .home1 {
    margin-top: -14rem;
  }
}/*# sourceMappingURL=pages.module.css.map */