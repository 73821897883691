@import "../../../sassVariables/variables";

.contactCardsContainer {
  @include flexCentered(column);
}

.card2 {
  @include flexCentered(column);
  height: 30rem;
  width: 25rem;
}

.text {
  font-size: 1.3rem;
  margin: 1.3rem auto 1.3rem auto;
  width: 25rem;
}

.imageContainer {
  height: 50%;
}
.imageContainer img {
  max-width: 100%;
  max-height: 100%;
}

.contact2 p {
  font-size: 0.8rem;
  margin: 0 0 0 0;
}

.contact2 a:link {
  text-decoration: none;
}

.card1 {
  @include flexCentered(column);
  height: 38rem;
  width: 25rem;
}

.title {
  font-size: 1.8rem;
  color: $VINA_BLACK;
}

.undertext {
  align-items: center;
  font-size: 0.8rem;
  margin: 0 auto 0.2rem auto;
  width: 80%;
}

.contactBox {
  width: 12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactBox p {
  width: 80%;
}

.contact2 a:link {
  text-decoration: none;
  color: $VINA_BLACK;
}
.contact2 p {
  color: $VINA_BLACK;
  font-size: 1.1rem;
  margin: 0 0 0 0;
}

.contact1 {
  margin: 0rem auto 0 auto;
}

.contact1 img {
  height: 2rem;
}

.contact1 a:link {
  text-decoration: none;
  color: $VINA_BLACK;
}

.contact1 p {
  color: $VINA_BLACK;
  font-size: 1.1rem;
  margin: 0 0 0 0;
}
