@import "../../../sassVariables/variables";

.header {
  width: 100%;
  height: 5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0 2rem;
  box-shadow: 0px 0px 1px $VINA_BLACK;
  background-color: $VINA_WHITE_TRASPARENT;
  transition-property: height;
  transition-duration: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header nav {
  height: 100%;
}

.header img {
  width: 14rem;
  display: flex;
  padding: 0.3rem;
  opacity: 100%;
  transition-property: width;
  transition-duration: 0.4s;
}

.header ul {
  height: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: right;
  margin: 0 0 0 0;
}

.header li {
  width: 8rem;
}

.header a {
  font-size: 0.9rem;
  color: $VINA_BLACK;
  text-decoration: none;
}

.header a:hover,
.header a:active,
.header a.active {
  border-style: solid;
  border-color: #666666;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-width: 1px;
}

.header.small {
  height: 3rem;
  background-color: #e9e7e3;
}

.header.small img {
  width: 7.8rem;
}

@media (max-width: 650px) {
  .header nav {
    display: none;
  }

  .headerSmall nav {
    display: none;
  }

  .header img {
    width: 10rem;
  }
}

@media (min-width: 651px) {
  .toggle {
    display: none;
  }
}
